import tableColumnsCache from "@/utils/tableColumnsCache"

const listVersion = 1
export const listName = 'sl.suporte.cliente.tasks'
export const listStorage = tableColumnsCache(listName, [
    {label: 'ID', name: 'id', active: true, sortable: true, ordering: 1},
    {label: 'Tipo', name: 'tipo', active: true, sortable: true, ordering: 2},
    {label: 'Resumo', name: 'titulo', active: true, sortable: true, ordering: 3},
    {label: 'Progresso', name: 'progress', active: false, sortable: true, ordering: 10},
    {label: 'Status', name: 'status', active: true, sortable: true, ordering: 12},
    {label: 'Previsão', name: 'dataPrazo', active: true, sortable: true, ordering: 11},
], listVersion)
